import React, { Suspense, lazy, useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import IntroAreaReverseOne from "@components/introareareverse/layout-5";
import BoxSection from "@components/BoxSection/layout-one/layout-6";
import IntroAreaOne from "@components/introarea/layout-4";
import CaseStudy from "@containers/global/case-study/layout-01";
import ContactArea from "@containers/contact-us";
import { caseStudiesData } from "@constants";
import QuoteForm from "@containers/translation-quote-form";
import UseScroll from "@containers/scroll";
const Footer = lazy(() => import("@layout/footer/layout-01"))
const ArtificialIntelligencePage = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 20) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo
        title="Artificial Intelligence"
        description="Unlock the power of AI for superior localization. Get human quality at a fraction of the cost."
      />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
          isMobile: isMobile
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["artificial-intelligence-page-header"]} />
        <QuoteForm />
        {
          (!isMobile || (isMobile && showRestComponents)) && <>
            <IntroAreaReverseOne layout={5} data={content["artificial-intelligence-intro-body"]} />
          </>
        }
        {
          showRestComponents && <>
            <BoxSection data={content["artificial-intelligence-boxes-body"]} />
            <IntroAreaOne layout={4} data={content["human-in-the-loop-data"]} />
            <CaseStudy data={caseStudiesData} />
            <ContactArea Layout={2} />
          </>
        }
      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  );
};

export const query = graphql`
  query ArtificialIntelligencePageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "artificial-intelligence" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

ArtificialIntelligencePage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default ArtificialIntelligencePage;
